@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(0px, 150px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 150px);
    opacity: 0;
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}

@keyframes pulse {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

#load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#load img {
  max-width: 150px;
  opacity: 1;
  animation: pulse 1s infinite ease-in-out alternate;
}

// Humo mayor
#load img path:nth-child(2) {
}

// Humo menor
#load img path:nth-child(4) {
}

// Peperoni 1
#load img path:nth-child(3) {
  opacity: 0;
  animation: fadeInOpacity 3s infinite;
  animation-delay: 0s;
}

// Peperoni 2
#load img path:nth-child(5) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 0.5s;
}

// Peperoni 3
#load img path:nth-child(6) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 0.7s;
}

// Peperoni 4
#load img path:nth-child(7) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 1s;
}


.logo-loader{
  width: 500px;
}