.logpot-text {
  color: #ff6644
}

.icon-image {
  width: 162px;
  height: 162px;
}

.icon-business {
  width: 130px;
  height: 130px;
}

.pointer {
  cursor: pointer;
}

.title-price {
  font-size: 0.3em;
}

.nav-item {
  cursor: pointer;
}

.owl-theme .owl-dots .owl-dot {
  background-color: #D6D6D6;
}

.owl-carousel .owl-dots button.active {
  background-color: var(--purple) !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: transparent !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: transparent !important;
}

.banner_section .banner_slider #frmae_slider::before {
  display: none !important;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 40px !important;
}

.company_logos {
  .owl-carousel .owl-item img {
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .about_app_section .about_text .app_statstic li p:first-child {
    font-size: 23px;
  }

  .about_app_section .about_text .app_statstic li p {
    font-size: 0.8em;
    margin-top: 8px;
  }
}

.banner_section .banner_text h1 {
  font-size: 3.1rem;
}

.banner_text {
  p {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.how_it_inner {
  padding-bottom: 40px !important;
}

.interface_section .screen_slider {
  min-height: 220px;
}

.banner_section .banner_text h1 {
  @media screen and (max-width: 1000px) {

    font-size: 30px;
  }
}

.top_footer {
  margin-top: 150px;

  @media screen and (max-width: 1000px) {

    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    h3 {
      margin-bottom: 30px !important;
    }

    .social_media {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    ul {
      li {
        display: flex;
        justify-content: center;
      }
    }
  }

  a {
    color: #fff !important;
    cursor: pointer;
  }
}

.free_app_section {
  margin-top: 100px;
}

.abt_side {
  text-align: center;
}

.logo {
  @media screen and (max-width: 1000px) {
    width: 200px;

    img {
      height: 100px;
      width: 100px;
    }
  }

}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  font-size: 13px !important;
}

.navbar {
  padding-top: 10px;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  font-size: 12px !important;
  padding: 9px 25px;
}

.icon-flag {
  width: 24px;
  border-radius: 2px;
  height: 25px;
  margin-top: -3px;
  margin-right: 8px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 2px 17px;

  @media screen and (max-width: 1000px) {
    padding: 5px 17px;
  }
}

.mobile-sub-menu {
  @media screen and (min-width: 1000px) {
    display: none !important;
  }

  .sub_menu {
    .icon-flag {
      margin-left: 8px;
    }
  }
}

.desktop-sub-menu {
  @media screen and (max-width: 1000px) {
    display: none !important;
  }
}

.button-cover {
  color: var(--text-white);
  background-color: var(--purple);
  font-size: 16px;
  padding: 15px 180px;
  border-radius: 17px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;
  width: 100% !important;

  @media screen and (max-width: 1000px) {
    margin-right: 15px;
    width: 100%;
    display: inline-block;
    position: initial;
    padding: 15px;
  }

}

.button-cover-outline {
  color: var(--purple);
  border: 2px solid var(--purple);
  font-size: 16px;
  width: 50px;
  padding: 13px 50px;
  border-radius: 17px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    margin-right: 15px;
    width: 100%;
    display: inline-block;
    width: 100% !important;
    margin-top: 20px;
  }
}

.button-cover:hover {
  color: var(--text-white);
}

.button-cover::after {
  animation: pulse-blue-small-sm 3.5s infinite;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  z-index: -1;
}


.trial_box_list {
  margin-top: 30px;

  ul {
    position: relative;
    display: flex;

    li {
      text-align: left;
      padding: 0 25px 0 0;
      font-size: 15px;
    }
  }
}

#navbarSupportedContent {
  .navbar-margin {
    @media screen and (max-width: 1000px) {
      margin-left: 0px !important;
    }
  }
}

.download_section .app_btn {
  display: flex;
  flex-direction: inherit;
  grid-gap: 10px;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
}

.download_section .app_btn li a.app_store {
  padding: 12px 40px;
}

.download_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--body-text-purple);
  position: relative;
  border-radius: 12px;
  transition: .4s all;


  @media screen and (max-width: 1000px) {
    padding: 9px 18px !important;
  }
}


.download_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.features_section .features_inner {
  background-color: var(--bg-white);
  padding: 70px 0;
  border-radius: 30px;
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.1) !important
}

.features_section .features_inner .features_block {
  max-width: 1170px;
  margin: 0 auto;
  padding: 10px;
}

.pricing_block {
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.1) !important
}


.features-icon {
  width: 60px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.pricing_block {
  min-height: 550px !important;
}

#getstarted {
  z-index: 9;

  .container-fluid {
    background-color: var(--bg-purple) !important;
  }
}

.feature_detail,
.app_statstic li,
.how_it_inner {
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.1) !important;
}

.feature_box .text {
  border: solid 1px #eae8f5;
  margin-bottom: 50px;
  padding: 30px;
  border-radius: 20px;
  background: #f6f5ff;
  height: 200px;
}

.product-image {
  @media screen and (max-width: 1000px) {
    max-width: 100% !important;
    margin-top: 60px;
  }
}

#navbarSupportedContent {
  .ul {
    @media screen and (max-width: 1000px) {
      margin-left: 0px !important;
    }
  }
}

.navbar {
  margin-right: 120px !important;

  @media screen and (max-width: 1000px) {
    margin-right: 0px !important;
  }
}

.button-buy {
  margin-right: 15px;
  border: none;
  font-size: 16px;
  padding: 15px 180px;
  border-radius: 17px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;
}

.mobile-sub-menu {
  .nav-link {
    background-color: transparent;
    border: none;
  }
  .sub_menu{
    text-align: left;

    button{
      background-color: transparent;
      border: none;
      padding-left: 0px;
      margin-top: 5px;
    }
  }
}