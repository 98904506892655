.owl-theme .owl-dots .owl-dot {
  background-color: #D6D6D6;
}

.owl-carousel .owl-dots button.active {
  background-color: var(--purple) !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: transparent !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: transparent !important;
}

.banner_section .banner_slider #frmae_slider::before {
  display: none !important;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 40px !important;
}

.company_logos {
  .owl-carousel .owl-item img {
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .about_app_section .about_text .app_statstic li p:first-child {
    font-size: 23px;
  }

  .about_app_section .about_text .app_statstic li p {
    font-size: 0.8em;
    margin-top: 8px;
  }
}

.banner_section .banner_text h1 {
  font-size: 3.1rem;
}

.banner_text {
  p {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 1.1em;
  }
}

.how_it_inner {
  padding-bottom: 40px !important;
}

.interface_section .screen_slider {
  min-height: 220px;
}

.banner_section .banner_text h1 {
  @media screen and (max-width: 1000px) {
    margin-top: 120px;
    font-size: 30px;
  }
}

.top_footer {
  margin-top: 150px;

  a {
    color: #fff !important;
    cursor: pointer;
  }
}

.free_app_section {
  margin-top: 100px;
}

.abt_side {
  text-align: center;
}

.logo {
  @media screen and (max-width: 1000px) {
    width: 200px;

    img {
      height: 100px;
      width: 100px;
    }
  }

}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  font-size: 13px !important;
}

.navbar {
  padding-top: 10px;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  font-size: 12px !important;
  padding: 9px 25px;
}

.icon-flag {
  width: 24px;
  border-radius: 2px;
  height: 25px;
  margin-top: -3px;
  margin-right: 8px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 2px 17px;

  @media screen and (max-width: 1000px) {
    padding: 5px 17px;
  }
}

.mobile-sub-menu {
  @media screen and (min-width: 1000px) {
    display: none !important;
  }

  .sub_menu {
    .icon-flag {
      margin-left: 8px;
    }
  }
}

.desktop-sub-menu {
  @media screen and (max-width: 1000px) {
    display: none !important;
  }
}

.button-cover {
  color: var(--text-white);
  background-color: var(--purple);
  font-size: 16px;
  padding: 15px 180px;
  border-radius: 17px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    padding: 15px;
  }

}

.button-cover-outline {
  color: var(--purple);
  border: 2px solid var(--purple);
  font-size: 16px;
  width: 50px;
  padding: 13px 50px;
  border-radius: 17px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;
}

.button-cover:hover {
  color: var(--text-white);
}

.button-cover::after {
  animation: pulse-blue-small-sm 3.5s infinite;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  z-index: -1;
}



.trial_box_list {
  margin-top: 30px;

  ul {
    position: relative;
    display: flex;

    li {
      text-align: left;
      padding: 0 25px 0 0;
      font-size: 15px;

      @media screen and (max-width: 1000px) {
        text-align: center;
      }
    }
  }
}

.download_section .app_btn {
  display: flex;
  flex-direction: inherit;
  grid-gap: 10px;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
}

.download_section .app_btn li a.app_store {
  padding: 12px 40px;
}

.download_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--body-text-purple);
  position: relative;
  border-radius: 12px;
  transition: .4s all;
}


.download_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.features_section .features_inner {
  background-color: var(--bg-white);
  padding: 70px 0;
  border-radius: 30px;
  box-shadow: 0px 4px 30px #EDE9FE;
}

.features_section .features_inner .features_block {
  max-width: 1170px;
  margin: 0 auto;
  padding: 10px;
}

.features-icon {
  width: 60px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.pricing_block {
  min-height: 750px !important;

  @media screen and (max-width: 1000px) {
    min-height: 600px !important;
  }

  h6 {
    font-weight: bolder;
    font-size: 1.2em;
    margin-top: 5px;
  }

  .free-months {
    margin-top: -30px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #FF6644;
    font-style: italic;
    text-align: center;
    left: 0px;
    right: 0px;
  }
}

.button-free {
  @media screen and (min-width: 1000px) {
    margin-top: 45px;
  }
}

#getstarted {
  z-index: 9;

  .container-fluid {
    background-color: var(--bg-purple) !important;
  }
}

.accordion {
  margin-top: 60px;

  .btn-link {
    color: #1f1f1f;
  }

  .card-header {
    background-color: #ededed47 !important;
  }
}

#home {
  .text {
    background-color: transparent !important;
    border: none;
    padding: 0px;
  }

  .features_inner {
    height: 450px;

    @media screen and (max-width: 1000px) {
      height: 100%;

      h2 {
        padding: 0px 20px;
      }

      .text {
        height: 50px;
      }
    }
  }

  .modern_ui_section {
    @media screen and (max-width: 1000px) {
      img {
        width: 100% !important;
      }

      .left_img {
        width: 100% !important;
      }
    }
  }

  .free_app_inner {
    @media screen and (max-width: 1000px) {
      padding: 0px 15px;

      .free_text {
        margin-top: -40px;
        margin-bottom: 40px;
      }
    }
  }
}